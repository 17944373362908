import XPhone from 'assets/images/XPhone';
import { ReactElement } from 'react';

interface ExpiredTokenProps {
  children: ReactElement;
}

const ExpiredToken: React.FC<ExpiredTokenProps> = ({ children }) => {
  return (
    <div className="md:w-[445px] grid justify-center items-center text-center p-4">
      <div>
        <div className="flex justify-center mb-6">
          <div className="w-[340px]">
            <XPhone />
          </div>
        </div>

        {children}
      </div>
    </div>
  );
};

export default ExpiredToken;
