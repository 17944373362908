import Button from 'atoms/Button';
import AccountVerified from 'molecules/AccountVerified';
import ExpiredToken from 'molecules/ExpiredToken';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { logInWithCustomToken } from 'services/firebase';
import { resendEmailVerification, verifyEmail } from 'services/user';

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const customToken = searchParams.get('__f_c_t') || '';
  const uid = searchParams.get('__i_u_d') || '';

  const userLogInQuery = useQuery(
    ['userLogIn', customToken],
    () => logInWithCustomToken(customToken),
    {
      enabled: !!customToken,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const IDToken = userLogInQuery.data || '';

  const verifyEmailQuery = useQuery(['verifyEmail', IDToken], () => verifyEmail(IDToken), {
    enabled: IDToken.length > 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const resendEmailVerificationQuery = useQuery(
    ['resendEmailVerification', uid],
    () => resendEmailVerification(uid),
    { enabled: false },
  );

  return (
    <div className="h-full flex justify-center items-center">
      {!customToken || userLogInQuery.isError || verifyEmailQuery.isError ? (
        <ExpiredToken>
          <>
            <h3 className="heading-03 text-neutral-01 mb-4">
              ¡Lo sentimos, tu enlace de verificación expiró!
            </h3>
            <p className="paragraph-04 text-primary-06 mb-8">
              Haz clic en{' '}
              <span className="paragraph-03"> Recibir nuevo enlace de verificación</span> y revisa
              tu correo electrónico para validar tu cuenta.
            </p>
            <div className="flex justify-center">
              <Button
                type="button"
                onClick={() => {
                  resendEmailVerificationQuery.refetch();
                }}
                className="w-[392px] px-4 py-2.5 text-button-02 bg-secondary-08 text-white"
              >
                <p>Recibir nuevo enlace de verificación</p>
              </Button>
            </div>
          </>
        </ExpiredToken>
      ) : null}
      {verifyEmailQuery.isSuccess ? <AccountVerified /> : null}
    </div>
  );
}
