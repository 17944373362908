import * as Yup from 'yup';

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'La contraseña no puede contener caracteres especiales')
    .min(8, 'La contraseña debe tener un mínimo de ocho caracteres')
    .matches(/[A-Z]/, 'La contraseña debe tener al menos una mayúscula')
    .matches(/[a-z]/, 'La contraseña debe tener al menos una minúscula')
    .matches(/[0-9]/, 'La contraseña debe tener al menos un número')
    .required('La contraseña es obligatoria'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
    .required('La contraseña es obligatoria'),
});

export default resetPasswordSchema;
