import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import Paragraph, { ParagraphType } from './Paragraph';

interface InputProps {
  id: string;
  type: string;
  icon?: JSX.Element;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  registerConfig: object;
  error?: string;
  disabled?: boolean;
  required?: boolean;
}

const Input: React.FC<InputProps> = ({
  // labelClasses = 'text-gray-700',
  // placeholder,
  id,
  type = 'text',
  label,
  icon,
  register,
  registerConfig,
  error,
  disabled = false,
  required = false,
}) => {
  let borderClasses = 'border-gray-300 ';
  let _labelClasses = 'text-primary-06 ';
  if (error) {
    _labelClasses = 'text-error-02';
    borderClasses = 'border-error-02 focus:ring-error-02 focus:border-error-02 ';
  }

  return (
    <div>
      <div className="flex items-center">
        <label htmlFor="email">
          <Paragraph
            type={ParagraphType['P4']}
            text={label}
            textColor={`${_labelClasses} leading-4`}
          />
        </label>
        {required ? <p className="ml-2 text-error-02">*</p> : null}
      </div>

      <div className={`mt-1 relative rounded-lg flex border overflow-hidden ${borderClasses}`}>
        <input
          id={id}
          disabled={disabled}
          {...register(id, registerConfig)}
          type={type}
          className={`w-full flex-1 min-w-0 block px-3 py-2 disabled:text-gray-500 disabled:cursor-not-allowed focus:outline-none focus:ring-0 ${
            icon && 'pr-4'
          }`}
          // placeholder={placeholder}
          // autoComplete={autocomplete}
        />
        <div className={`pr-3 flex items-center  ${icon ? '' : 'right-0'} `}>{icon}</div>
      </div>
      {error && <p className="mt-2 overline-01 text-error-02">{error}</p>}
    </div>
  );
};

export default Input;
