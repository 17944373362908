import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface RegexValidatorProps {
  text: string;
  validated?: boolean;
}

const RegexValidator: React.FC<RegexValidatorProps> = ({ text, validated = false }) => {
  return (
    <div className="mb-4">
      <span className={`${validated ? 'text-success-01' : 'text-neutral-03'} mr-2`}>
        <FontAwesomeIcon icon={faCheck} />
      </span>
      <span className={`paragraph-04 ${validated ? 'text-primary-06' : 'text-neutral-03'}`}>
        {text}
      </span>
    </div>
  );
};

export default RegexValidator;
