import axios, { AxiosResponse } from 'axios';

const API_BASE_URL = `https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`;

export const verifyEmail = async (IDToken: string): Promise<AxiosResponse> => {
  const { data } = await axios.get(`${API_BASE_URL}/api/v1/user/verifyEmail`, {
    headers: {
      Authorization: `Bearer ${IDToken}`,
    },
  });

  return data;
};

export const resetPassword = async (IDToken: string, body: object): Promise<AxiosResponse> => {
  const { data } = await axios.post(`${API_BASE_URL}/api/v1/user/resetPassword`, body, {
    headers: {
      Authorization: `Bearer ${IDToken}`,
    },
  });

  return data;
};

export const resendEmailVerification = async (uid: string): Promise<AxiosResponse> => {
  const { data } = await axios.get(`${API_BASE_URL}/api/v1/p/resend`, {
    params: {
      uid,
    },
  });

  return data;
};
