import ThankYouImage from 'assets/images/ThankYouImage';

export default function AccountVerified() {
  return (
    <div className=" grid grid-cols-1 lg:grid-cols-2 gap-x-16 h-full px-5 md:px-0">
      <div className="w-full flex justify-center items-center">
        <div className="w-[200px] lg:w-[320px]">
          <ThankYouImage />
        </div>
      </div>
      <div className="grid lg:items-center justify-center text-left ">
        <div>
          <h3 className="heading-03 text-neutral-01 leading-6">
            ¡Validamos tu cuenta correctamente!
          </h3>
          <br />
          <p className="paragraph-04 text-primary-06 leading-4">
            Ya puedes enviar tus diezmos y ofrendas de manera rápida y sencilla.
          </p>
        </div>
      </div>
    </div>
  );
}
