import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import ResetPasswordImage from 'assets/images/ResetPasswordImage';
import Button from 'atoms/Button';
import Input from 'atoms/Input';
import RegexValidator from 'atoms/RegexValidator';
import ExpiredToken from 'molecules/ExpiredToken';
import ResetPasswordSuccessfully from 'molecules/ResetPasswordSuccessfully';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import resetPasswordSchema from 'schemas/resetPassword';
import { logInWithCustomToken } from 'services/firebase';
import { resetPassword } from 'services/user';

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const customToken = searchParams.get('__f_c_t') || '';

  // Local state
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);

  const userLogInQuery = useQuery(
    ['userLogIn', customToken],
    () => logInWithCustomToken(customToken),
    {
      enabled: !!customToken,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const IDToken = userLogInQuery.data || '';

  const resetPasswordMutation = useMutation(['resetPassword', IDToken], (data: object) =>
    resetPassword(IDToken, data),
  );

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const password = watch('password') || '';

  const onSubmit = (data: object) => {
    resetPasswordMutation.mutate(data);
  };

  if ((!IDToken && userLogInQuery.isError) || (!IDToken && userLogInQuery.isSuccess)) {
    return (
      <div className="h-full w-full flex justify-center px-4 md:px-0">
        <ExpiredToken>
          <>
            <h3 className="heading-03 text-neutral-01 mb-4 text-left md:text-center">
              ¡Lo sentimos, este enlace para restablecer tu contraseña expiró!
            </h3>
            <p className="paragraph-04 text-primary-06 mb-8 text-left md:text-center">
              Para restablecer tu contraseña, simplemente{' '}
              <span className="paragraph-03">
                regresa a la aplicación y solicita la recuperación de contraseña
              </span>{' '}
              una vez más.
              <br /> <br />
              Si tienes alguna pregunta, no dudes en contactar a nuestro equipo de soporte.
            </p>
          </>
        </ExpiredToken>
      </div>
    );
  }

  return (
    <div className="h-full w-full flex justify-center">
      {resetPasswordMutation.isSuccess ? (
        <ResetPasswordSuccessfully />
      ) : (
        <div className="max-w-7xl grid grid-cols-1 lg:grid-cols-2  h-full px-5 md:px-0 ">
          <div className="grid grid-cols-1 lg:grid-cols-7  text-left mt-10 lg:mt-40 ">
            <div className="lg:col-span-5 lg:col-start-2 text-center ">
              <div className="mb-14">
                <h2 className="heading-02 text-neutral-01 leading-6">
                  ¡Estás a un paso de volver a acceder!
                </h2>
                <br />
                <p className="paragraph-02 text-primary-06 leading-5">Crea una nueva contraseña</p>
              </div>
              <form className="text-left" onSubmit={handleSubmit(onSubmit)} method="POST">
                <Input
                  id="password"
                  type={passwordVisible ? 'text' : 'password'}
                  label="Contraseña nueva"
                  register={register}
                  registerConfig={{}}
                  required
                  icon={
                    <Button
                      className="border-none p-0 m-0 cursor-pointer"
                      type="button"
                      icon={
                        <FontAwesomeIcon
                          icon={passwordVisible ? faEyeSlash : faEye}
                          className="text-secondary-04"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                      }
                    />
                  }
                  error={errors?.password?.message}
                />
                <div className="pl-3.5 mt-4">
                  <p className="paragraph-03 text-primary-06 mb-4">
                    Requisitos para crear una contraseña
                  </p>

                  <RegexValidator text="Mínimo ocho caracteres" validated={password?.length > 7} />
                  <RegexValidator
                    text="Al menos una mayúscula"
                    validated={/[A-Z]/.test(password)}
                  />
                  <RegexValidator
                    text="Al menos una minúscula"
                    validated={/[a-z]/.test(password)}
                  />
                  <RegexValidator text="Al menos un número" validated={/\d/.test(password)} />
                </div>
                <div className="mt-4">
                  <Input
                    id="confirmPassword"
                    type={confirmPasswordVisible ? 'text' : 'password'}
                    label="Confirmar contraseña"
                    register={register}
                    registerConfig={{}}
                    required
                    icon={
                      <Button
                        className="border-none p-0 m-0 cursor-pointer"
                        type="button"
                        icon={
                          <FontAwesomeIcon
                            icon={confirmPasswordVisible ? faEyeSlash : faEye}
                            className="text-secondary-04"
                            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                          />
                        }
                      />
                    }
                    error={errors?.confirmPassword?.message}
                  />
                </div>
                <Button
                  disabled={Object.keys(errors).length > 0}
                  type="submit"
                  className="mt-16 button-02 bg-secondary-08 text-white rounded-xl"
                >
                  <p>Actualizar contraseña</p>
                </Button>
              </form>
            </div>
          </div>
          <div className="w-full hidden lg:grid lg:items-center">
            <div className="w-40 md:w-56 lg:w-[31rem] grid justify-center items-center">
              <ResetPasswordImage />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
