import React from 'react';

export enum ParagraphType {
  'P1' = '1',
  'P2' = '2',
  'P3' = '3',
  'P4' = '4',
}

interface ParagraphProps {
  type: ParagraphType;
  text: string;
  textColor?: string;
}

const Paragraph: React.FC<ParagraphProps> = ({ text, textColor = 'text-black', type }) => {
  let fontWeight = 'font-semibold';
  let fontSize = 'text-[14px]';
  if (type === ParagraphType['P2']) {
    fontSize = 'text-[14px]';
    fontWeight = 'font-normal';
  } else if (type === ParagraphType['P3']) {
    fontSize = 'text-[12px]';
    fontWeight = 'font-semibold';
  } else if (type === ParagraphType['P4']) {
    fontSize = 'text-[12px]';
    fontWeight = 'font-normal';
  }

  return <p className={`${fontSize} ${fontWeight} ${textColor}`}>{text}</p>;
};

export default Paragraph;
