import Icon_Color from 'assets/images/Icon_Color';
import Imagotipo_Black from 'assets/images/Imagotipo_Black';
import React from 'react';

const RequestInformation = () => {
  return (
    <div className="h-full w-full md:flex md:justify-center">
      <div className="max-w-[400px] w-full">
        <div className="mt-8 mb-10 flex justify-center">
          <Imagotipo_Black />
        </div>
        <div className="p-6">
          {/* ICON SECTION */}
          <div className="flex items-center mb-8">
            <div>
              <Icon_Color />
            </div>
            <div>
              <h5 className="ml-4 heading-05 text-primary-06">App Mi Fidelidad</h5>
            </div>
          </div>

          <div>
            <div>
              <h2 className="heading-02 text-secondary-04">Solicitud de baja de información</h2>
            </div>
            <div className="mt-2">
              <h5 className="heading-05 text-primary-06">
                Sigue estos pasos para desactivar tu cuenta:
              </h5>
            </div>
            <div>
              <p className="paragraph-04 text-neutral-01 mt-2">
                Para desactivar tu cuenta, comunícate con nosotros a través del correo electrónico:
              </p>
              <p className="overline-line-01 text-primary-06 mt-2 text-[14px]">
                contacto.mifidelidad@iasd.org.mx
              </p>
              <p className="paragraph-04 text-neutral-01 mt-6">
                Recuerda que al momento de recibir tu solicitud, se archivarán todos tus datos
                personales y tu cuenta quedará desactivada.
              </p>
              <p className="paragraph-03 text-neutral-01 mt-2">
                Te confirmaremos vía correo electrónico cuando tu cuenta quede desactivada.
              </p>
              <p className="paragraph-04 text-neutral-01 mt-6">¡Gracias por confiar en nosotros!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestInformation;
