import './App.css';
import VerifyEmail from './organisms/VerifyEmail';

function App() {
  return (
    <div className="App">
      <VerifyEmail />
    </div>
  );
}

export default App;
