import Icon_Color from 'assets/images/Icon_Color';
import Imagotipo_Black from 'assets/images/Imagotipo_Black';
import React from 'react';

const PrivacyPolicies = () => {
  return (
    <div className="h-full w-full md:flex md:justify-center">
      <div className="max-w-[400px] w-full">
        <div className="mt-8 mb-10 flex justify-center">
          <Imagotipo_Black />
        </div>
        <div className="p-6">
          {/* ICON SECTION */}
          <div className="flex items-center mb-8">
            <div>
              <Icon_Color />
            </div>
            <div>
              <h5 className="ml-4 heading-05 text-primary-06">App Mi Fidelidad</h5>
            </div>
          </div>

          <div>
            <div>
              <h2 className="heading-02 text-secondary-04">Políticas de privacidad</h2>
            </div>

            <div>
              <p className="paragraph-04 text-neutral-01 mt-6">
                “Mi Fidelidad” reconoce la naturaleza sensible de su información personal y
                financiera. Es nuestra más alta prioridad e interés asegurar la confidencialidad de
                cualquier información que usted proporcione y tomamos un enfoque proactivo y las
                acciones apropiadas para proteger su privacidad. Cuando usted confía su información
                a “Mi Fidelidad”, puede estar seguro de que sólo se utilizará dentro de nuestros
                estrictos parámetros:
              </p>

              <h5 className="heading-05 text-primary-06 mt-6">Protección de la información:</h5>
              <ul className="list-decimal border-box list-inside">
                <li className="paragraph-04 text-neutral-01 mt-6">
                  No vendemos, alquilamos ni intercambiamos ninguna información personal o de
                  tarjetas de crédito o débito con ninguna organización adventista o no adventista.
                </li>
                <li className="paragraph-04 text-neutral-01 mt-6">
                  Dentro de “Mi Fidelidad”, salvaguardamos su información cuidadosamente utilizando
                  los mejores y últimos métodos de seguridad disponibles. Ulitizamos cortafuegos
                  para bloquear el acceso no autorizado de individuos o redes y utilizamos
                  transmisiones seguras para encriptar la información entre usted y “Mi Fidelidad”
                </li>
              </ul>

              <h5 className="heading-05 text-primary-06 mt-6">Quién puede ver su información</h5>
              <p className="paragraph-04 text-neutral-01 mt-6">
                Para poder devolver sus diezmos y ofrendas a través de “Mi Fidelidad”, es necesario
                registrarse utilizando una dirección de correo electrónico válida. El registro es
                gratuito para cualquier persona. La información personal y los detalles de sus
                envios de diezmos y ofrendas proporcionados son confidenciales entre usted y su
                iglesia local. La información de la tarjeta de crédito o débito no se almacena bajo
                ninguna circunstancia y sólo el importe total de su envío de diezmos y ofrendas se
                envía al banco para su procesamientoa través de un motor de pago (Stripe). Los
                números de las tarjetas de crédito o débito se utilizan únicamente para procesar su
                envío y no se utilizan para ningún otro propósito.
              </p>

              <h5 className="heading-05 text-primary-06 mt-6">
                Cómo utiliza “Mi Fidelidad” el correo electrónico
              </h5>
              <p className="paragraph-04 text-neutral-01 mt-6">
                “Mi Fidelidad” nunca enviará correos electrónicos solicitando información personal o
                de cuentas, como contraseñas o números de tarjetas de crédito. En la mayoría de los
                casos, no le enviaremos correos electrónicos a menos que usted haya iniciado un
                diálogo por correo electrónico con nosotros o nos haya solicitado previamente que
                nos comuniquemos con usted de esta manera.
              </p>

              <p className="paragraph-04 text-neutral-01 mt-6">
                Si tiene alguna pregunta sobre esta política, envíe un correo electrónico a
                <span className="font-bold text-underline"> contacto.mifidelidad@iasd.org.mx</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicies;
