interface ButtonProps {
  className: string;
  type: 'button' | 'submit' | 'reset' | undefined;
  icon?: JSX.Element;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  className,
  children,
  icon,
  onClick = undefined,
  disabled = false,
  type = 'button',
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`button w-full font-medium font-default disabled:cursor-not-allowed ${className}`}
    >
      <div className={`${icon ? 'flex justify-center items-center' : 'block'}`}>
        {icon}
        {children}
      </div>
    </button>
  );
};

export default Button;
