import KeyFlowers from 'assets/images/KeyFlowers';

export default function ResetPasswordSuccessfully() {
  return (
    <div className="w-[445px] grid justify-center items-center text-center">
      <div>
        <div className="flex justify-center mb-6">
          <div className="w-[340px]">
            <KeyFlowers />
          </div>
        </div>

        <h3 className="heading-03 text-neutral-01 mb-4">
          ¡Actualizaste tu contraseña exitosamente!
        </h3>

        <p className="paragraph-04 text-primary-06 mb-8">
          ¡Listo! Ya puedes iniciar sesión con tu contraseña nueva.
        </p>
      </div>
    </div>
  );
}
