import Icon_Color from 'assets/images/Icon_Color';
import Imagotipo_Black from 'assets/images/Imagotipo_Black';
import React from 'react';
import { Link } from 'react-router-dom';

const TechnicalSupport = () => {
  return (
    <div className="h-full w-full md:flex md:justify-center">
      <div className="max-w-[400px] w-full">
        <div className="mt-8 mb-10 flex justify-center">
          <Imagotipo_Black />
        </div>
        <div className="p-6">
          {/* ICON SECTION */}
          <div className="flex items-center mb-8">
            <div>
              <Icon_Color />
            </div>
            <div>
              <h5 className="ml-4 heading-05 text-primary-06">App Mi Fidelidad</h5>
            </div>
          </div>

          <div>
            <div>
              <h2 className="heading-02 text-secondary-04">Soporte técnico</h2>
            </div>

            <div>
              <p className="paragraph-04 text-neutral-01 mt-2">
                ¡Gracias por utilizar <span className="font-bold">Mi Fidelidad!</span> Si tienes
                algún problema o necesitas ayuda, no dudes en comunicarte con nuestro equipo de
                soporte al correo:
              </p>
              <p className="overline-line-01 text-primary-06 mt-2 text-[14px]">
                soporte.mifidelidad@iasd.org.mx
              </p>
              <p className="paragraph-03 text-secondary-08 mt-6">Horario de atención</p>
              <p className="paragraph-04 text-neutral-01 mt-2">
                Lunes a viernes, de <span className="font-bold">9:00 a.m. a 6:00 p.m. </span>
                hora de la Ciudad de México.
              </p>
              <p className="paragraph-03 text-neutral-01 mt-10">Desactivar cuenta</p>
              <p className="paragraph-04 text-neutral-01 mt-2">
                Si deseas desactivar tu cuenta y dar de baja tu información de la aplicación, da
                clic en el siguiente enlace y sigue las instrucciones:
              </p>
              <br />
              <Link
                to="/requestDeleteInformation"
                className="paragraph-03 text-secondary-08 cursor-pointer"
              >
                Solicitar baja de información
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalSupport;
