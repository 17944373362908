import PrivacyPolicies from 'organisms/PrivacyPolicies';
import RequestInformation from 'organisms/RequestInformation';
import ResetPassword from 'organisms/ResetPassword';
import TechnicalSupport from 'organisms/TechnicalSupport';
import VerifyEmail from 'organisms/VerifyEmail';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/privacyPolicies" element={<PrivacyPolicies />} />
          <Route path="/technicalSupport" element={<TechnicalSupport />} />
          <Route path="/requestDeleteInformation" element={<RequestInformation />} />
          <Route path="/verifyEmail" element={<VerifyEmail />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/app" element={<App />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
